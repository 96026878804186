jQuery(document).ready(function ($) {

	// Hide nav on scroll
	let lastScroll = 0;
	let hideNav = 0;
	let navClass = (window.innerWidth < 992) ? '.nav-mobile' : '.nav-bar';
	window.addEventListener("scroll", () => {
		let currentScroll = window.pageYOffset;
		if (currentScroll - lastScroll > 0) {
			if (currentScroll > 500 && hideNav == 0) {
				hideNav = 1;
				$(navClass).slideUp();
			}
		} else {
			if (hideNav == 1) {
				hideNav = 0;
				$(navClass).slideDown('400', function() {
					$('.notification-slider').slick('reinit');
				});
			}
		}
		lastScroll = currentScroll;
	});

	// Nav hover
	/*
	$('.nav-bar').hover(
		function () {
			$(this).addClass('active');
		}, function () {
			if ($('.nav-search').is(":hidden") && $('.nav-form').is(":hidden")) {
				$(this).removeClass('active');
			}
		}
	);
	*/

	// Nav hover radius effect
	$('.nav-left ul li').hover(
		function () {
			$('.nav-bar').addClass('active');
		}, function () {
			$('.nav-bar').removeClass('active');
		}
	);

	function hideDropdowns() {
		if ($('.mobile-menu').is(":visible")) {
			$('.mobile-menu').hide();
			$('.js-hamburger-open').hide();
			$('.js-hamburger-close').show();
			$('.nav-mobile').removeClass('active');
		}

		if ($('.mobile-search').is(":visible")) {
			$('.mobile-search').hide();
			$('.js-mobile-search-open').hide();
			$('.js-mobile-search-close').show();
			$('.nav-mobile').removeClass('active');
		}

		if ($('.mobile-phone-numbers').is(":visible")) {
			$('.mobile-phone-numbers').hide();
			$('.js-mobile-phone-numbers-open').hide();
			$('.js-mobile-phone-numbers-close').show();
			$('.nav-mobile').removeClass('active');
		}
	}

	// Mobile nav buttons
	$('.js-hamburger').click(function (event) {
		let jsHamburger = $(this);
		if ($('.mobile-menu').is(":visible")) {
			hideDropdowns();
		} else {
			hideDropdowns();
			$('.nav-mobile').addClass('active');
			$('.mobile-menu').show();
			jsHamburger.children('.js-hamburger-close').hide();
			jsHamburger.children('.js-hamburger-open').show();
		}
	});

	// Mobile subnav buttons
	$('.js-submenu').click(function (event) {
		let navSubMenu = $(this).parent();
		if (navSubMenu.hasClass('active')) {
			navSubMenu.children('ul').slideUp();
			navSubMenu.removeClass('active');
		} else {
			navSubMenu.addClass('active');
			navSubMenu.children('ul').slideDown();
		}
	});

	// Mobile subnav buttons
	$('.js-mobile-search').click(function (event) {
		if ($('.mobile-search').is(":visible")) {
			hideDropdowns();
		} else {
			hideDropdowns();
			$('.nav-mobile').addClass('active');
			$('.mobile-search').show();
			$(this).children('.js-mobile-search-close').hide();
			$(this).children('.js-mobile-search-open').show();
		}
	});

	// Mobile phone numbers
	$('.js-mobile-phone-numbers').click(function (event) {
		if ($('.mobile-phone-numbers').is(":visible")) {
			hideDropdowns();
		} else {
			hideDropdowns();
			$('.nav-mobile').addClass('active');
			$('.mobile-phone-numbers').show();
			$(this).children('.js-mobile-phone-numbers-close').hide();
			$(this).children('.js-mobile-phone-numbers-open').show();
		}
	});

	$('.js-nav-search').click(function (event) {
		if ($('.nav-search').is(":visible")) {
			$('.nav-search').hide();
		} else {
			$('.nav-form').hide();
			$('.nav-search').show();
		}
	});

	$('.js-nav-form').hover(function (event) {
		event.preventDefault();
		if ($('.nav-form').is(":visible")) {
			$('.nav-form').hide();
		} else {
			$('.nav-search').hide();
			$('.nav-form').show();
		}
	});

	$('.js-gform').hover(function (event) {
		event.preventDefault();
		if ($('.nav-form').is(":visible")) {
			$('.nav-form').hide();
		} else {
			$('.nav-search').hide();
			$('.nav-form').show();
		}
	});

	// Inline label forms: Add active class if input has value
	$('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number]').each(function () {
		if ($(this).val().length) {
			$(this).closest('.gfield').addClass('active');
		}
	});

	$('.js-gform select').on('change', function() {
		if ($(this).val() === "") {
			$(this).closest('.gfield').removeClass('active');
		} else {
			$(this).closest('.gfield').addClass('active');
		}
	});

	// Inline label forms: Add active class on focus
	$('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number]').on('focus', function () {
		$(this).closest('.gfield').addClass('active');
	});

	// Inline label forms: Remove active class on blur
	$('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number]').on('blur', function () {
		if (!$(this).val().length || $(this).val().includes('_')) {
			$(this).closest('.gfield').removeClass('active');
		}
	});

	// Home page locations tabs
    $('.home-locations-tabs a').click(function (event) {
		event.preventDefault();
		let tab = $(this).attr('href');
		$(tab).siblings('div').hide();
		$(this).parent().siblings().children().removeClass('active');
		$(tab).show();
		$(this).addClass('active');
	});

	// Nav featured products slider
	/*
	const featuredContainer = document.querySelector(".featured-container");
	const featuredSlide = document.querySelector(".featured-slide");
	const featuredPrevButton = document.querySelector(".featured-arrow-prev");
	const featuredNextButton = document.querySelector(".featured-arrow-next");
	featuredNextButton.addEventListener("click", () => {
		const featuredSlideWidth = featuredSlide.clientWidth;
		featuredContainer.scrollLeft += featuredSlideWidth;
	});
	featuredPrevButton.addEventListener("click", () => {
		const featuredSlideWidth = featuredSlide.clientWidth;
		featuredContainer.scrollLeft -= featuredSlideWidth;
	});

	let slidesContainer = document.getElementById("slides-container");
	if(slidesContainer){
		const slidesContainer = document.getElementById("slides-container");
		const slide = document.querySelector(".slide");
		const prevButton = document.getElementById("slide-arrow-prev");
		const nextButton = document.getElementById("slide-arrow-next");
		nextButton.addEventListener("click", () => {
			const slideWidth = slide.clientWidth;
			slidesContainer.scrollLeft += slideWidth;
		});
		prevButton.addEventListener("click", () => {
			const slideWidth = slide.clientWidth;
			slidesContainer.scrollLeft -= slideWidth;
		});
	}
	*/

	$('.slide').hover(
		function() {
			$(this).children('.content').css("display", "flex").hide().fadeIn();
		}, function() {
			$(this).children('.content').fadeOut();
		}
	);

	$('.icon-info').click(function() {
		$(this).sibling('.content').css("display", "flex").hide().fadeIn();
	});

	$('.product-filter li').click(function() {
		$(this).siblings().removeClass('active');
		$(this).addClass('active');
		let showClass = '.' + $(this).text().replace(/[^A-Z0-9]/ig, "").toLowerCase();
		if (showClass === '.all') {
			$('.product-cards').children().show();
		} else {
	 		$('.product-cards').children().hide();
			$(showClass).show();
		}
	});

	$('.specialize-slider').slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><img src="/wp-content/themes/atlanticspas/dist/images/icon-next.png" alt="Previous" /></button>',
		nextArrow: '<button type="button" class="slick-next" aria-label="Next"><img src="/wp-content/themes/atlanticspas/dist/images/icon-next.png" alt="Next" /></button>',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	});

	$('.product-slider').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><img src="/wp-content/themes/atlanticspas/dist/images/icon-slide-left.png" alt="Previous" /></button>',
		nextArrow: '<button type="button" class="slick-next" aria-label="Next"><img src="/wp-content/themes/atlanticspas/dist/images/icon-slide-right.png" alt="Next" /></button>',
	});

	$('.brand-slider').slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><img src="/wp-content/themes/atlanticspas/dist/images/icon-slide-left.png" alt="Previous" /></button>',
		nextArrow: '<button type="button" class="slick-next" aria-label="Next"><img src="/wp-content/themes/atlanticspas/dist/images/icon-slide-right.png" alt="Next" /></button>',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	});

	$('.js-read-more').click(function(event) {
		event.preventDefault();
		let content = $(this).siblings('.content');
		if (content.hasClass('active')) {
			content.removeClass('active');
			$(this).html('Read More');
		} else {
			
			content.addClass('active');
			$(this).html('Read Less');
		}
	});

	$('.product-list-filter li').click(function() {
		$(this).siblings().removeClass('active');
		$(this).addClass('active');
		let showClass = '.' + $(this).text().replace(/[^A-Z0-9]/ig, "").toLowerCase();
		if (showClass === '.all') {
			$('.product-list-cards').children().show();
		} else {
	 		$('.product-list-cards').children().hide();
			$(showClass).show();
		}
	});

	$('.js-full-content').click(function(event) {
		event.preventDefault();
		let parentContent = $(this).parent();
		parentContent.hide();
		parentContent.siblings('.full-content').show();
	});

	$('.product-image-slider').slick({
		infinite: true,
		slidesToShow: 1,
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><img src="/wp-content/themes/atlanticspas/dist/images/icon-slide-left.png" alt="Previous" /></button>',
		nextArrow: '<button type="button" class="slick-next" aria-label="Next"><img src="/wp-content/themes/atlanticspas/dist/images/icon-slide-right.png" alt="Next" /></button>',
	});

	$('.notification-slider').slick({
		arrows: false,
		autoplay: true,
		autoplaySpeed: 4500,
		fade: true,
	});

	$('.install-gallery-filter li').click(function() {
		$(this).siblings().removeClass('active');
		$(this).addClass('active');
		let showClass = '.' + $(this).text().replace(/[^A-Z0-9]/ig, "").toLowerCase();
		if (showClass === '.all') {
			$('.install-gallery').children().show();
		} else {
	 		$('.install-gallery').children().hide();
			$(showClass).show();
		}
	});

	function show_popup(){
		const cookieValue = document.cookie.split("; ").find((row) => row.startsWith("disablePopUp="))?.split("=")[1];
		if (cookieValue != 1) {
			$("#pop-up").css('display', 'flex');
		}
	};
	window.setTimeout(show_popup, 5000);

	$('.js-popup-close').click(function(event) {
		$("#pop-up").css('display', 'none');
		document.cookie = "disablePopUp=1; SameSite=None; Secure";
	});

});

